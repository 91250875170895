<template>
  <div>
    <clini-profile-component />
  </div>
</template>

<script>
import CliniProfileComponent from "@/components/hospitals-clinics/ClinicProfileComponent";

export default {
  name: "ClinicProfile",
  title: "Clinic Profile | Baja California Health Tourism",
  components: { CliniProfileComponent },
};
</script>

<style scoped></style>
